<template>
    <div class="carousel-gallery my-10 lg:my-20 overflow-hidden">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full lg:w-1/3">
                    <slot name="text"></slot>
                </div>
                <div class="grid-col w-full lg:w-2/3 mt-6 lg:mt-0 relative">
                    <div class="carousel-gallery__container">
                        <div ref="carousel" class="keen-slider">
                            <slot name="slides"></slot>
                        </div>
                    </div>
                    <div class="hidden lg:block">
                        <button
                            v-if="currentSlideId > 0"
                            class="absolute top-1/3 -left-1 arrow-btn arrow-btn--yellow arrow-btn--left arrow-btn--square"
                            @click="carousel.prev()"
                            aria-label="Slide précédente"
                        ></button>
                        <button
                            v-if="currentSlideId < nbSlides - 2"
                            class="absolute top-1/3 -right-1 arrow-btn arrow-btn--yellow arrow-btn--right arrow-btn--square"
                            @click="carousel.next()"
                            aria-label="Slide suivante"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
    name: "CarouselGallery",

    data() {
        return {
            carousel: null,
            currentSlideId: 0,
        }
    },

    computed: {
        nbSlides() {
            if (this.carousel) {
                return this.carousel.slides.length;
            }

            return null;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.carousel = new KeenSlider(this.$refs.carousel, {
                initial: this.currentSlideId,
                slides: {
                    perView: 1.2,
                    spacing: 16,
                },
                breakpoints: {
                    '(min-width: 768px)': {
                        slides: {
                            perView: 2.1,
                            spacing: 32,
                        }
                    },
                    '(min-width: 1024px)': {
                        slides: {
                            perView: 2,
                            spacing: 32,
                        }
                    }
                },
                slideChanged: (s) => {
                    this.currentSlideId = s.track.details.rel
                },
            });
        })
    },
}
</script>

<style scoped lang="scss">
.carousel-gallery {
    :deep(.carousel-gallery__container) {
        .keen-slider {
            overflow: visible;
        }
    }

    &__container {
        @screen lg {
            clip-path: inset(-100vw -100vw -100vw 0);
        }
    }
}
</style>
