<template>
    <div class="base-accordion">
        <button class="base-accordion__button w-full" :class="{'base-accordion__button--opened' : isOpened}" @click="isOpened = !isOpened">
            <slot name="button"></slot>
        </button>
        <div class="base-accordion__content">
            <transition
                name="expand"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
            >
                <div v-if="isOpened">
                    <slot name="content"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseAccordion",

        data() {
            return {
                isOpened: false,
            }
        },

        methods: {
            enter(element) {
                element.style.height = 'auto';

                const height = getComputedStyle(element).height;
                element.style.height = 0;

                // Force repaint
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = height;
                });
            },

            afterEnter(element) {
                element.style.height = 'auto';
            },

            leave(element) {
                element.style.height = getComputedStyle(element).height;

                // Force repaint
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = 0;
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .base-accordion {
        .expand-enter-active,
        .expand-leave-active {
            transition: height 300ms;
            overflow: hidden;
        }

        .expand-enter,
        .expand-leave-to {
            height: 0;
        }
    }
</style>
