<template>
    <div class="actor-map grid-row justify-center">
        <div class="grid-col w-full lg:w-6/12">
            <form
                :class="backgroundColor ? 'bg-white' : 'bg-beige'"
                class=" p-2 rounded-xl mb-10 flex flex-inline max-h-15"
                @submit.prevent.stop="searchPosition(5)"
            >
                <input
                    type="text"
                    ref="addressField"
                    name="address"
                    v-model="address"
                    :class="backgroundColor ? 'bg-white' : 'bg-beige'"
                    class="actor-map__input block w-full border-none placeholder:body-2 placeholder-body-2 text-grey-11 placeholder:text-grey-40"
                    placeholder="Entrez votre adresse, ville ou code postal"
                >
                <button
                    class="btn btn--yellow"
                    type="submit"
                >
                    Rechercher
                </button>
            </form>
        </div>
    </div>

    <div class="grid-row justify-center">
        <div
            class="grid-col w-full gap-4 lg:flex lg:justify-between"
        >
            <div class="w-full lg:w-7/12 flex flex-col mb-4">
                <div class="map-squeezed-size">
                    <l-map :key="markers" class="z-0" ref="map" v-model:zoom="zoom" v-model:center="center" :options="mapOptions">
                        <l-tile-layer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            layer-type="base"
                            name="OpenStreetMap"
                        ></l-tile-layer>
                        <l-marker
                            v-for="(actor, key) in markers"
                            :key="`actor-${key}`"
                            :lat-lng="[actor.latitude, actor.longitude]"
                            @click="markerClickHandler(actor)"
                        >
                            <l-icon
                                :icon-url="getActorIcon(actor)"
                                :icon-size="getActorIconSize(actor)"
                                :class-name="actor.id === this.showActor?.id ? 'selected-marker' : ''"
                            />
                        </l-marker>
                    </l-map>
                </div>
                <a
                    v-if="cta && cta.url"
                    :href="cta.url"
                    :aria-label="cta.ariaLabel"
                    :target="cta.target"
                    class="mt-4 link underline flex justify-start"
                >{{ cta.label }}</a>
            </div>
            <div
                :key="closestActors.length"
                class="w-full lg:w-5/12 flex flex-col justify-items-start text-center"
            >
                <div class="flex flex-col border-t border-b border-grey-80 py-4 md:py-6 mb-4 md:mb-6">
                    <p class="lead text-start">Filtres</p>
                    <div class="flex flex-wrap py-2 pr-10">
                        <div
                            v-for="(type, key) in types"
                            :key="key"
                            class="my-1 flex justify-start w-1/2 text-left"
                        >
                            <input
                                type="checkbox"
                                class="input-checkbox mr-2 mt-0.5 rounded-sm"
                                :id="type.value"
                                :class="backgroundColor ? 'bg-beige' : 'bg-white'"
                                :value="type.value"
                                v-model="selectedType"
                            >
                            <label
                                :for="type.value"
                                class="body-2">
                                {{ type.label }}
                            </label>
                        </div>
                    </div>
                </div>

                <transition name="fade" mode="out-in">
                    <div v-if="showActorMapCards">
                        <p class="body-2 mb-2 md:mb-4 text-start">
                            {{ resultsHeading }}
                        </p>

                        <template v-for="(actor, key) in filteredClosestActors" :key="`card-${key}`">
                            <actor-map-card
                                v-if="key < 2 || isShowMoreEnabled"
                                class="mb-4"
                                :class="{ 'actor-map-card__selected': actor.id === this.showActor?.id }"
                                :actor="actor"
                                :background-color="this.backgroundColor"
                                :results-heading="this.resultsHeading"
                                @click="selectActor(actor)"
                            >
                            </actor-map-card>
                        </template>

                        <button
                            v-if="filteredClosestActors.length > 2"
                            class="link underline lg:mt-6"
                            @click="showMoreHandler">
                            <template v-if="isShowMoreEnabled">Voir moins d'acteurs</template>
                            <template v-else>Voir plus d'acteurs</template>
                        </button>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {LIcon, LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";
import axios from 'axios';
import ActorMapCard from "../components/ActorMapCard.vue";

const pictoActorAutre = require('@/static/images/actor-map/autres.svg'),
    pictoActorAssociation = require('@/static/images/actor-map/association.svg'),
    pictoActorCollectivite = require('@/static/images/actor-map/collectivite.svg'),
    pictoActorEcole = require('@/static/images/actor-map/ecole.svg'),
    GEOAPIFY_API_URL = 'https://api.geoapify.com/v1/geocode/search',
    GEOAPIFY_API_KEY = '2df5a0dc5b234e2b952e22d5cf5d8a14';


export default {
    name: "ActorMap",

    components: {
        LIcon,
        LMarker,
        LMap,
        LTileLayer,
        ActorMapCard,

    },

    props: {
        cta: { type: Object, required: true },
        actors: { type: Object, required: true },
        backgroundColor: { type: Boolean, required: true},
        types: { type: Object, required: true},
        resultsHeading: { type: String, default : 'Les acteurs au plus proche de chez vous'}
    },

    data() {
        return {
            zoom: 5,
            center : [46.226648, 2.216739],
            mapOptions  : {
                attributionControl: false,
                zoomControl: true,
                scrollWheelZoom: false,
            },
            showMap: true,
            address: '',
            closestActors: this.actors,
            showActorMapCards: false,
            apiKey: '2df5a0dc5b234e2b952e22d5cf5d8a14',
            isShowMoreEnabled: false,
            selectedType: [],
            showActor: null,
        };
    },

    computed: {
        /**
         * Returns the closest actors that match the selected types.
         * If no types are selected, it returns all the closest actors.
         *
         * @returns {Array} - An array of actor objects that match the selected types.
         */
        filteredClosestActors() {
            // If no types are selected, return all the closest actors
            if (this.selectedType.length === 0) {
                return this.closestActors;
            }

            // Filter the closest actors based on the selected types
            return this.closestActors.filter((actor) => {
                return this.selectedType.includes(actor.type.value);
            });
        },

        /**
         * Returns the actors that match the selected types.
         * If no types are selected, it returns all the actors.
         *
         * @returns {Array} - An array of actor objects that match the selected types.
         */
        markers() {
            // If no types are selected, return all the actors
            if (this.selectedType.length === 0) {
                return this.actors;
            }

            // Filter the actors based on the selected types
            return this.actors.filter((actor) => {
                return this.selectedType.includes(actor.type.value);
            });
        },
    },

    watch: {
        /**
         * Watcher for the `selectedType` data property.
         * Calls the `beforeFilter` method whenever `selectedType` changes.
         */
        selectedType() {
            this.beforeFilter();
        },
    },

    methods: {
        /**
         * Searches for the position based on the user's input address.
         * It makes a geocoding request to get the coordinates of the address,
         * finds the closest actors to these coordinates, and updates the map view.
         *
         * @param {number} resultsNumber - The number of closest actors to find. Defaults to 5.
         */
        async searchPosition(resultsNumber = 5) {
            // Reset the currently shown actor
            this.showActor = null;

            try {
                // Make a geocoding request with the user's input address
                const response = await this.makeGeocodingRequest(this.address);

                // Extract the coordinates from the response
                const coordinates = {
                    lat: response.data.features[0].geometry.coordinates[1],
                    lng: response.data.features[0].geometry.coordinates[0]
                }

                // Find the closest actors to these coordinates
                this.closestActors = this.getClosest(coordinates.lat, coordinates.lng, resultsNumber);

                // Show the actor map cards
                this.showActorMapCards = true;

                // Update the map view to the coordinates
                this.$refs.map.leafletObject.setView([coordinates.lat, coordinates.lng], 9);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Makes a geocoding request to the Geoapify API with the given address.
         *
         * @param {string} address - The address to geocode.
         * @returns {Promise} - A Promise that resolves to the response of the geocoding request.
         */
        makeGeocodingRequest(address) {
            // Define the configuration for the axios request
            const config = {
                method: 'get',
                url: `${GEOAPIFY_API_URL}?text=${address}&apiKey=${GEOAPIFY_API_KEY}`,
                headers: {}
            };
            return axios(config);
        },

        /**
         * Finds the closest actors to a given location.
         *
         * @param {number} destLat - The latitude of the location.
         * @param {number} destLng - The longitude of the location.
         * @param {number} number - The number of closest actors to find. Defaults to 5.
         * @returns {Array} - An array of the closest actors to the location.
         */
        getClosest(destLat, destLng, number = 5) {
            // Reset the closest actors array
            this.closestActors = [];

            // Create a deep copy of the actors array
            let actors = JSON.parse(JSON.stringify(this.actors));

            // Calculate the distance from each actor to the location
            actors.forEach((actor) => {
                actor.distance = this.distance(actor.latitude, actor.longitude, destLat, destLng);
            });

            // Sort the actors by distance from the location
            actors = actors.sort((a, b) => a.distance - b.distance);

            // Filter the actors that are within a 50 km radius from the location
            const actorsInCircle = actors.filter((actor) => actor.distance <= 50);

            // If there are actors within a 50 km radius, return the closest ones
            if (actorsInCircle.length > 0) {
                return actorsInCircle.slice(0, number);
            }

            // If there are no actors within a 50 km radius, return the closest actors
            return actors.slice(0, number);
        },

        /**
         * Calculates the distance between two geographical points using the Haversine formula.
         *
         * @param {number} lat1 - The latitude of the first point.
         * @param {number} lon1 - The longitude of the first point.
         * @param {number} lat2 - The latitude of the second point.
         * @param {number} lon2 - The longitude of the second point.
         * @returns {number} - The distance between the two points in kilometers.
         */
        distance(lat1, lon1, lat2, lon2) {
            // The radius of the Earth in kilometers
            const R = 6371;

            // The difference in latitudes, converted to radians
            const dLat = this.toRad(lat2 - lat1);

            // The difference in longitudes, converted to radians
            const dLon = this.toRad(lon2 - lon1);

            // The Haversine formula
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2));
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            // The distance in kilometers
            return R * c;
        },

        /**
         * Converts a value from degrees to radians.
         *
         * @param {number} Value - The value in degrees to convert to radians.
         * @returns {number} - The value in radians. The returned value represents an angle in radians,
         * which is a unit of measurement used in mathematics to describe angles.
         */
        toRad(Value) {
            return Value * Math.PI / 180;
        },

        /**
         * Toggles the state of the `isShowMoreEnabled` data property.
         * This function is used to control the visibility of additional actor cards.
         */
        showMoreHandler() {
            this.isShowMoreEnabled = !this.isShowMoreEnabled;
        },

        /**
         * Returns the appropriate icon for an actor based on their type.
         *
         * @param {Object} actor - The actor object.
         * @returns {{}} - The path to the icon for the actor's type.
         */
        getActorIcon(actor){
            switch (actor.type.label)
            {
                case 'Association':
                    return pictoActorAssociation;
                case 'Collectivité':
                    return pictoActorCollectivite;
                case 'École':
                    return pictoActorEcole;
                default:
                    return pictoActorAutre;
            }
        },

        /**
         * Returns the appropriate icon size for an actor based on whether they are the currently selected actor.
         *
         * @param {Object} actor - The actor object.
         * @returns {Array} - The size of the icon for the actor. Returns [64, 64] if the actor is currently selected, and [48, 48] otherwise.
         */
        getActorIconSize(actor) {
            return actor.id === this.showActor?.id ? [64, 64] : [48, 48];
        },

        /**
         * Handles the click event on a marker on the map.
         * It selects the actor associated with the clicked marker, shows the actor map cards,
         * and updates the closest actors to the clicked actor.
         *
         * @param {Object} actor - The actor object associated with the clicked marker.
         */
        markerClickHandler(actor) {
            // Select the actor associated with the clicked marker
            this.selectActor(actor);

            // Show the actor map cards
            this.showActorMapCards = true;

            // Reset the closest actors array
            this.closestActors = [];

            // Update the closest actors to the clicked actor
            this.closestActors = this.getClosest(actor.latitude, actor.longitude, 5);
        },

        /**
         * Selects an actor and updates the map view to center on the actor's location.
         *
         * @param {Object} actor - The actor object to select.
         */
        selectActor(actor) {
            // Set the selected actor
            this.showActor = actor;

            // Use Vue's $nextTick to wait until the DOM is updated
            this.$nextTick(() => {
                // Set the view of the map to the actor's location
                this.$refs.map.leafletObject.setView([
                    this.showActor.latitude,
                    this.showActor.longitude,
                ], 9);
            });
        },

        /**
         * Deselects the current actor and save the current center of the map.
         */
        beforeFilter() {
            // Deselect the current actor
            this.showActor = null;

            // Save the current center of the map
            this.center[0] = this.$refs.map.leafletObject.getCenter().lat;
            this.center[1] = this.$refs.map.leafletObject.getCenter().lng


        },
    },
};

</script>

<style scoped lang="scss">
.actor-map {
    &__input[placeholder] {
        text-overflow: ellipsis;
    }
}

.map-squeezed-size {
    height: 460px;
    width: 100%;

    .leaflet-container {
        border-radius: 0.75rem;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

:deep.leaflet-container{
    .selected-marker {
        z-index: 1000 !important;
    }
}


</style>
