<template>
    <div class="account-btn">
        <a v-if="!isUserLoggedIn" :href="loginUrl" class="btn btn--yellow">Se connecter</a>
        <div v-else class="account-btn__container relative btn btn--yellow">
            <span class="account-btn__label flex items-center">Mon compte</span>
            <ul class="account-btn__links absolute inset-x-0 bg-yellow px-4 pb-4 pt-6">
                <li>
                    <a :href="profileUrl" class="body-3 normal-case hover:underline">Mon profil</a>
                </li>
                <li>
                    <a :href="logoutUrl" class="body-3 normal-case hover:underline">Se déconnecter</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountBtn",

    props: {
        isUserLoggedIn: {
            type: Boolean,
            required: true,
        },
        profileUrl: {
            type: String,
            default: null,
        },
        logoutUrl: {
            type: String,
            default: null,
        },
        loginUrl: {
            type: String,
            default: null,
        },
    }
}
</script>

<style scoped lang="scss">
    .account-btn {
        &__label {
            &::after {
                content: '';
                background-image: url('@/static/images/icons/chevron-up-black.svg');
                @apply w-4 h-4 bg-cover bg-center ml-2 rotate-180 transition-transform duration-200 ease-in-out;
            }
        }

        &__links {
            top: calc(100% - 1.25rem);

            border-radius: 0.8125rem;
            @apply hidden;
        }

        &__container {
            &:hover {
                .account-btn__links {
                    @apply flex flex-col;
                }

                .account-btn__label::after {
                    @apply rotate-0;
                }
            }
        }
    }
</style>
