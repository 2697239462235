<template>
    <transition name="overlay-fade" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "OverlayFadeTransition"
}
</script>

<style scoped lang="scss">
.overlay-fade-enter-active,
.overlay-fade-leave-active {
    transition: opacity 0.2s ease;
}

.overlay-fade-enter-from,
.overlay-fade-leave-to {
    @apply opacity-0;
}
</style>
