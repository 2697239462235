<template>
    <div class="faq bg-beige py-10 lg:py-22">
        <div class="grid-container">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-5/6">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FaqAccordion",
}

</script>

<style scoped lang="scss">
    .faq {
        :deep(.faq__accordion) {
            .base-accordion__button {
                .faq__accordion-button {
                    &::after {
                        @apply absolute w-4 h-4 right-0 bg-cover bg-center ml-2;
                        content: '';
                        background-image: url('@/static/images/icons/plus.svg');
                    }
                }
            }
        }

        :deep(.faq__accordion) {
            .base-accordion__button--opened {
                .faq__accordion-button {
                    &::after {
                        @apply w-3 h-1 bg-cover bg-center ml-2;
                        content: '';
                        background-image: url('@/static/images/icons/minus.svg');
                    }
                }
            }

        .base-accordion__content {
                @apply pt-4 lg:pt-5
            }
        }
    }
</style>
