<template>
    <article
        class="toolbox-item-card relative flex flex-col justify-between w-full h-full rounded-3xl"
        :class="`bg-${color}`"
    >
        <div class="flex px-2 pt-3">
            <div v-if="item.step" class="pill mr-1" :class="`pill--${item.step.color}`">{{ item.step.title }}</div>
            <div v-if="item.type" class="pill pill--grey">{{ item.type }}</div>
        </div>
        <div class="flex p-4 lg:p-6">
            <div class="flex-1">
                <p class="h5">{{ item.title }}</p>
                <p class="caption mt-2">{{item.description}}</p>
            </div>
            <div class="flex-shrink-0 flex items-end pl-3">
                <a
                    v-if="item.linkType === 'login'"
                    :href="item.url"
                    class="toolbox-item-card__link flex items-center justify-center w-8 h-8 rounded-full bg-grey-94 transition-colors duration-200 hover:bg-grey-63"
                    aria-label="Se connecter"
                >
                    <img src="@/static/images/icons/lock.svg" alt="Lien vers la page de connexion" aria-hidden="true">
                </a>
                <a
                    v-if="item.linkType === 'pdf'"
                    :href="item.url"
                    target="_blank"
                    class="toolbox-item-card__link flex items-center justify-center w-8 h-8 rounded-full bg-grey-94 transition-colors duration-200 hover:bg-grey-63"
                    aria-label="Télécharger le PDF"
                >
                    <img src="@/static/images/icons/pdf-link.svg" alt="Lien vers un PDF" aria-hidden="true">
                </a>

                <base-overlay v-if="item.linkType === 'video'">
                    <template #button>
                        <span class="toolbox-item-card__link flex items-center justify-center w-8 h-8 rounded-full bg-grey-94 transition-colors duration-200 hover:bg-grey-63">
                            <img src="@/static/images/icons/video.svg" alt="Lien vers une vidéo" aria-hidden="true">
                        </span>
                    </template>
                    <template #content>
                        <div class="aspect-video">
                            <iframe style="width: 100%; height: 100%;" :src="item.url" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </template>
                </base-overlay>
            </div>
        </div>
    </article>
</template>

<script>
import BaseOverlay from "@/app/components/BaseOverlay";

export default {
    name: "ToolboxItemCard",

    props: {
        item: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: 'white',
        },
    },

    components: {
        BaseOverlay,
    },
}
</script>

<style scoped lang="scss">
.toolbox-item-card {
    &__link {
        &::before {
            @apply absolute inset-0 z-100;
            content: '';
        }
    }
}

</style>
