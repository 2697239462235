<template>
    <div class="carousel-steps my-10 lg:my-20 overflow-hidden">
        <div class="grid-container">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-2/3 text-center">
                    <slot name="heading"></slot>
                </div>
                <div class="grid-col w-full mt-8 lg:mt-12 relative">
                    <div class="carousel-steps__container">
                        <div ref="carousel" class="keen-slider">
                            <slot name="slides"></slot>
                        </div>
                    </div>

                    <div class="hidden lg:block">
                        <button
                            v-if="currentSlideId > 0"
                            class="absolute top-1/2 -left-1 -translate-y-1/2 arrow-btn arrow-btn--green arrow-btn--left arrow-btn--square"
                            @click="carousel.prev()"
                            aria-label="Slide précédente"
                        ></button>
                        <button
                            v-if="currentSlideId < nbSlides - 3"
                            class="absolute top-1/2 -right-1 -translate-y-1/2 arrow-btn arrow-btn--green arrow-btn--right arrow-btn--square"
                            @click="carousel.next()"
                            aria-label="Slide suivante"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
    name: "CarouselSteps",

    data() {
        return {
            carousel: null,
            currentSlideId: 0,
        }
    },

    computed: {
        nbSlides() {
            if (this.carousel) {
                return this.carousel.slides.length;
            }

            return null;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.carousel = new KeenSlider(this.$refs.carousel, {
                initial: this.currentSlideId,
                slides: {
                    perView: 1.2,
                    spacing: 24,
                },
                breakpoints: {
                    '(min-width: 1024px)': {
                        slides: {
                            perView: 3,
                            spacing: 32,
                        }
                    }
                },
                slideChanged: (s) => {
                    this.currentSlideId = s.track.details.rel
                },
            });
        })
    },
}
</script>

<style scoped lang="scss">
.carousel-steps {
    :deep(.carousel-steps__container) {
        .keen-slider {
            overflow: visible;
        }

        .keen-slider__slide {
            @apply overflow-visible;

            &::after {
                content: '';
                @apply absolute -right-14 w-20 h-14 bg-cover bg-center rotate-180;
            }

            &:nth-child(odd) {
                &::after {
                    background-image: url('@/static/images/steps/plant-reverse.svg');
                    @apply top-2/3;
                }
            }
            &:nth-child(even) {
                &::after {
                    background-image: url('@/static/images/steps/plant.svg');
                    @apply bottom-2/3;
                }
            }

            &:last-child {
                &::after {
                    @apply hidden;
                }
            }
        }
    }
}
</style>
