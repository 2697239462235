<template>
    <div ref="root" class="password-double-check">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PasswordDoubleCheck",

    data() {
        return {
            mainInput: null,
            confirmationInput: null,
            password: null,
            confirmation: null,
        }
    },

    watch: {
        password (newValue) {
            if (newValue !== this.confirmation) {
                this.confirmationInput.setCustomValidity('Les mots de passe ne sont pas identiques.');
                return;
            }

            this.confirmationInput.setCustomValidity('');
        },

        confirmation (newValue) {
            if (newValue !== this.password) {
                this.confirmationInput.setCustomValidity('Les mots de passe ne sont pas identiques.');
                return;
            }
            this.confirmationInput.setCustomValidity('');
        },
    },

    mounted() {
        this.mainInput = this.$refs.root.querySelectorAll('.input-text')[0];
        this.confirmationInput = this.$refs.root.querySelectorAll('.input-text')[1];

        this.mainInput.onchange = (event) => {
            this.password = event.target.value;
        }

        this.confirmationInput.onchange = (event) => {
            this.confirmation = event.target.value;
        }
    },
}
</script>
