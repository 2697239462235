<template>
    <div class="flex flex-col mt-4 lg:mt-6">
        <label for="postalAddress" class="body-2">
            {{ labelPostalAddress }} *
        </label>
        <input id="postalAddress" type="text"  class="input-text mt-1.5" v-model="postalAddress" required>
    </div>

    <div class="flex flex-col mt-4 lg:mt-6">
        <label for="zipCode" class="body-2">
            {{ labelZipCode }} *
        </label>
        <input id="zipCode" type="text"  class="input-text mt-1.5" v-model="zipCode" required>
    </div>

    <div class="flex flex-col mt-4 lg:mt-6">
        <label for="city" class="body-2">
            {{ labelCity }} *
        </label>
        <input id="city" type="text"  class="input-text mt-1.5" v-model="city" required>
    </div>

    <div class="flex flex-col mt-4 lg:mt-6">
        <label for="country" class="body-2">
            {{ labelCountry }} *
        </label>
        <input id="country" type="text" class="input-text mt-1.5" v-model="country" required>
    </div>

    <input type="hidden" :value="concatAddress" name="fields[locationsAddress]">
</template>

<script>
export default {
    name: "AddressInputs",

    props: {
        labelCity: {
            type: String,
            default: '',
        },
        labelCountry: {
            type: String,
            default: '',
        },
        labelPostalAddress: {
            type: String,
            default: '',
        },
        labelZipCode: {
            type: String,
            default: '',
        },
    },

    created() {
        this.postalAddress = localStorage.getItem("postalAddress") || null;
        this.zipCode = localStorage.getItem("zipCode") || null;
        this.city = localStorage.getItem("city") || null;
        this.country = localStorage.getItem("country") || null;
    },


    watch: {
        postalAddress(value) {
            localStorage.setItem("postalAddress", value);
        },
        zipCode(value) {
            localStorage.setItem("zipCode", value);
        },
        city(value) {
            localStorage.setItem("city", value);
        },
        country(value) {
            localStorage.setItem("country", value);
        },
    },

    data: () => ({
        postalAddress: null,
        zipCode: null,
        city : null,
        country : null,
    }),

    computed:{
        concatAddress(){
            return this.postalAddress + ', ' + this.zipCode + ' ' + this.city + ', ' + this.country;
        }
    }
}


</script>

<style scoped lang="scss">

</style>
