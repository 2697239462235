<template>
     <textarea
         id="locationsDescription"
         class="input-text mt-1.5"
         name="fields[locationsDescription]"
         rows="4"
         maxlength="250"
         v-model="content"
     ></textarea>
     <slot name="text"></slot>
    <div
        class="flex mt-1"
        :class="displayError() ? 'justify-between': 'justify-end'"
    >
        <span
            v-if="displayError()"
            class="text-alert"
        >{{errorMessage}}</span>
        <span class="ml-4">{{this.counter()}}/250</span>
    </div>

</template>

<script>
export default {
    name: "TextArea",

    props: {
        placeholder: {
            type: String,
            default: '',
        },
        errorMessage: {
            type: String,
            default: '',
        },
        valueCustom: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            content: this.valueCustom,
        }
    },

    methods: {
        counter() {
            return this.content.length;
        },

        displayError() {
            if(this.content.length >= 250) {
                return true;
            }
        }
    },
}

</script>

<style scoped lang="scss">

</style>
<script setup>
</script>
