<template>
    <div v-if="!hidden"
        :role="role"
         class="fixed top-0 right-0 z-100 min-w-[250px] flex justify-between m-4 px-2 py-3 rounded-md text-white font-bold"
         :class="{
            'bg-[#ef476f]': isError,
            'bg-[#06d6a0]': !isError,
         }"
    >
        <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="32px"
                 height="32px"
                 viewBox="0 0 24 24"
                 fill="#ffffff"
                 class="mr-2"
            >
                <g v-if="!isError">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                </g>
                <path v-else d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"/>
            </svg>

            <slot />
        </div>

        <div class="text-3xl relative top-[-12px] ml-2 cursor-pointer" @click="close">×</div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    type: String,
});

const role = computed(() => {
    return props.type === 'error' ? 'alert' : 'status';
});

const isError = computed(() => {
    return props.type === 'error';
});

const hidden = ref(false);
function close() {
    hidden.value = true;
}
</script>

<style scoped>

</style>
