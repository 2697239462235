<template>
    <div class="base-overlay inline-flex">
        <button @click="toggleOverlay">
            <slot name="button"></slot>
        </button>

        <overlay-fade-transition>
            <div v-if="isOverlayOpened" class="base-overlay__container fixed z-100 inset-0 w-screen h-screen bg-black/50">
                <div class="grid-container h-full">
                    <div class="grid-row h-full items-center justify-center">
                        <div class="grid-col w-full lg:w-5/6">
                            <div class="flex justify-end">
                                <button class="cross-btn cross-btn--square cross-btn--yellow mb-2" @click="toggleOverlay" aria-label="Fermer l'overlay"></button>
                            </div>
                            <slot name="content"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </overlay-fade-transition>
    </div>
</template>

<script>
import OverlayFadeTransition from "@/app/transitions/OverlayFadeTransition";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export default {
    name: "BaseOverlay",

    components: {
        OverlayFadeTransition,
    },

    data() {
        return {
            isOverlayOpened: false,
        }
    },

    methods: {
        toggleOverlay() {
            this.isOverlayOpened = !this.isOverlayOpened;

            if (this.isOverlayOpened) {
                disableBodyScroll(document.body);
                return;
            }

            enableBodyScroll(document.body);
        },
    },
}
</script>

<style scoped>

</style>
