<template>
    <div class="testimonials bg-beige py-10 lg:py-22">
        <div class="grid-container">
            <div class="grid-row justify-center">
                <div class="grid-col">
                    <div class="flex flex-col items-center lg:flex-row">
                        <div class="lg:w-5/12">
                            <transition name="image-fade" mode="out-in">
                                <img v-if="currentSlide.image" :key="`image-${currentSlideId}`"  class="testimonials__image" :width="currentSlide.image.width" :height="currentSlide.image.height" :src="currentSlide.image.url" :alt="currentSlide.image.alt">
                            </transition>
                        </div>
                        <div class="w-full lg:w-6/12 flex flex-col justify-center text-center mt-4 lg:mt-0">
                            <p class="body-2 mb-2 lg:mb-4">
                                <strong>Témoignages</strong>
                            </p>

                            <transition name="text-fade" mode="out-in">
                                <div :key="`text-${currentSlideId}`">
                                    <h4 class="h4 mb-4 lg:mb-6">{{ currentSlide.quote }}</h4>
                                    <p class="body-3 w-2/3 lg:w-full mx-auto lg:mx-0 mb-6 lg:mb-8">{{ currentSlide.text }}</p>
                                </div>
                            </transition>

                            <div class="flex justify-center">
                                <span v-for="(slide, key) in slides" :key="key" :class="currentSlideId === key ? 'testimonials__current-step' : 'testimonials__step'" class="flex mx-1" @click="this.currentSlideId = key"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-col w-full flex justify-center lg:justify-end mt-6 lg:mt-0">
                <button @click="decrementCurrentId" class="btn arrow-btn arrow-btn--yellow arrow-btn--left mx-2"></button>
                <button @click="incrementCurrentId" class="btn arrow-btn arrow-btn--yellow mx-2"></button>
            </div>
        </div>

        <svg class="svg" width="0" height="0">
            <clipPath id="testimonials-mask" clipPathUnits="objectBoundingBox">
                <path d="M1,0.502 C0.996,0.712,0.828,0.926,0.647,0.989 C0.547,1,0.438,0.992,0.338,0.964 C0.098,0.91,-0.015,0.768,0.003,0.502 C-0.015,0.235,0.098,0.094,0.338,0.04 C0.438,0.011,0.547,-0.014,0.647,0.014 C0.828,0.077,0.996,0.292,1,0.502"></path>
            </clipPath>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "TestimonialsCarrousel",
        props: {
            slides: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                currentSlideId: 0,
            }
        },

        computed: {
            currentSlide() {
                return this.slides[this.currentSlideId];
            }
        },

        methods: {
            incrementCurrentId() {
                if (this.currentSlideId === this.slides.length - 1) {
                    this.currentSlideId = 0;
                }
                else {
                    this.currentSlideId++;
                }
            },
            decrementCurrentId() {
                if (this.currentSlideId === 0) {
                    this.currentSlideId = this.slides.length - 1;
                }
                else {
                    this.currentSlideId--;
                }
            },
        }
    }

</script>

<style scoped lang="scss">
.testimonials {
    &__current-step {
        width: 48px;
        height: 4px;
        border-radius: 24px;
        background: var(--primary-yellow, #F6B50D);
        transition: width 0.3s;
    }
    &__step {
        width: 24px;
        height: 4px;
        border-radius: 24px;
        background: rgba(0, 0, 0, 0.20);
        transition: width 0.3s;
    }

    &__image {
        clip-path: url(#testimonials-mask);
    }
}

.text-fade-enter-active,
.text-fade-leave-active,
.image-fade-enter-active,
.image-fade-leave-active {
    transition: opacity 0.4s ease, transform 0.4s ease;
}

.text-fade-enter-from,
.text-fade-leave-to,
.image-fade-enter-from,
.image-fade-leave-to {
    @apply opacity-0;
}

.image-fade-leave-to {
    @apply scale-95;
}

.image-fade-enter-from {
    @apply scale-105;
}

.text-fade-leave-to {
    @apply -translate-x-20;
}

.text-fade-enter-from {
    @apply translate-x-20;
}
</style>
