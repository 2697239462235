<template>
    <nav class="pagination flex justify-center" role="navigation">
        <button
            :disabled="value === 1"
            :class="{'pagination__button--disabled': value === 1}"
            class="pagination__button pagination__button--previous"
            aria-label="Page précédente"
            @click="value--"
        ></button>

        <div class="body-2 flex items-center md:hidden px-6" aria-hidden="true">
            Page {{ value }}/{{pageCount}}
        </div>

        <div class="pagination__list">
            <template
                v-for="i in pageCount"
                :key="i"
            >
                <template v-if="i > 2 && i > value - 2 && i < value">...</template>
                <button
                    v-if="i === 1 || i === pageCount || i < value + 2 && i > value - 2"
                    :class="{'pagination__list-item--current': i === value}"
                    class="pagination__list-item"
                    @click="this.value = i"
                >
                    <span>{{ i }}</span>
                </button>
                <template v-if="i < pageCount - 1 && i < value + 2 && i > value">...</template>
            </template>
        </div>

        <button
            :disabled="value === pageCount"
            :class="{'pagination__button--disabled': value === pageCount}"
            class="pagination__button pagination__button--next"
            aria-label="Page suivante"
            @click="value++"
        ></button>
    </nav>
</template>

<script>
export default {
    name: "PaginationNav",

    props: {
        pageCount: {
            type: Number,
            required: true,
        },
        modelValue: {
            type: Number,
            required: true,
        },
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>

<style scoped>

</style>
