<template>
    <div class="orientator-animation bg-white flex justify-center" ref="root">
        <slot></slot>
    </div>
</template>

<script>
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "OrientatorAnimation",

    mounted() {
        const containerBg = this.$refs.root.querySelector('.orientator__container-bg');
        const container = this.$refs.root.querySelector('.orientator__container');

        this.$nextTick(() => {
            let timeline = gsap.timeline({
                paused: true,
            });

            timeline.fromTo(container, {
                scale: 0.7,
            }, {
                scale: 1,
            }, 0);

            timeline.fromTo(containerBg, {
                width: containerBg.offsetWidth,
            }, {
                width: '100vw',
            }, 0);

            ScrollTrigger.create({
                trigger: '.orientator-animation',
                end: 'bottom 80%',
                scrub: 1,
                animation: timeline,
            });
        })
    }
}
</script>

<style scoped>

</style>
