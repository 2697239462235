<template>
    <div class="toolbox-list">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full mb-2 lg:mb-3">
                    <div class="grid-row">
                        <div class="grid-col w-full lg:w-1/4">
                            <button class="btn btn--yellow mr-10 lg:mr-0 mb-2 md:mb-0" @click="areFiltersVisible = !areFiltersVisible">
                                <img src="@/static/images/icons/filter.svg" alt="Filtres" class="mr-2" aria-hidden="true">
                                <template v-if="areFiltersVisible">
                                    Masquer les filtres
                                </template>
                                <template v-else>
                                    Afficher les filtres
                                </template>
                            </button>

                            <button v-if="selectedFilters.length > 0" @click="selectedFilters = []" class="link underline mt-2 lg:mt-4">Effacer les filtres</button>
                        </div>
                        <div class="order-first lg:order-last grid-col w-full lg:w-3/4 mb-6 lg:mb-0">
                            <form
                                class="h-15 bg-white p-2 rounded-xl flex flex-inline"
                                @submit.prevent="searchItems()"
                            >
                                <input
                                    type="text"
                                    ref="keywordsSearchInput"
                                    v-model="searchQuery"
                                    name="keywords"
                                    class="block w-full border-none placeholder:body-2 text-grey-11 placeholder:text-grey-40 truncate"
                                    placeholder="Rechercher une fiche pratique, un document..."
                                >
                                <button
                                    class="btn btn--yellow"
                                    type="submit"
                                >
                                    Rechercher
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <aside v-if="areFiltersVisible" class="toolbox-list__filters grid-col w-full lg:w-1/4 mt-2 lg:mt-3">
                    <base-accordion
                        v-for="(filter, key) in availableFilters"
                        :key="`filter-${key}`"
                        class="flex flex-col border-t border-grey-80 py-6"
                    >
                        <template #button>
                            <span class="toolbox-list__filter-heading flex justify-between items-center lead">{{ filter.label }}</span>
                        </template>

                        <template #content>
                            <div
                                v-for="(option, i) in filter.options"
                                :key="`option-${key}-${i}`"
                                :class="i === 0 ? 'mt-6' : 'mt-2'"
                                class="flex items-start mb-2"
                            >
                                <input
                                    :id="`${filter.name}-${i}`"
                                    type="checkbox"
                                    :name="filter.name"
                                    :value="option.slug"
                                    class="input-checkbox mt-0.5 mr-3 bg-beige"
                                    v-model="selectedFilters"
                                >
                                <label :for="`${filter.name}-${i}`" class="body-2 cursor-pointer">{{ option.label }}</label>
                            </div>
                        </template>
                    </base-accordion>

                    <div class="flex flex-col items-center lg:hidden">
                        <button class="btn btn--yellow mb-4" @click="areFiltersVisible = false">
                            Valider
                        </button>

                        <button
                            v-if="selectedFilters.length > 0"
                            @click="selectedFilters = []"
                            class="link underline mb-4"
                        >Effacer les filtres</button>
                    </div>
                </aside>
                <div :class="areFiltersVisible ? 'lg:w-3/4' : 'lg:w-full'" class="grid-col w-full mt-2 lg:mt-0">
                    <div class="grid-row">
                        <div
                            v-for="(item, key) in paginatedItems"
                            :key="`item-${key}`"
                            :class="areFiltersVisible ? 'lg:w-1/2 xl:w-1/3' : 'lg:w-1/3 xl:w-1/4'"
                            class="grid-col w-full md:w-1/2 my-2 lg:my-3"
                        >
                            <toolbox-item-card :item="item"></toolbox-item-card>
                        </div>

                        <template v-if="paginatedItems.length === 0">
                            <div class="grid-col w-full my-2 lg:my-3 flex justify-center items-center">
                                <p class="body-1 mt-4 lg:mt-6">Aucun résultat pour votre recherche</p>
                            </div>
                        </template>

                        <div class="grid-col w-full mt-10">
                            <pagination-nav v-if="pageCount > 1" :page-count="pageCount" v-model="currentPageId"></pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAccordion from "@/app/components/BaseAccordion";
import ToolboxItemCard from "@/app/components/ToolboxItemCard";
import PaginationNav from "@/app/components/PaginationNav";

export default {
    name: "ToolboxList",

    props: {
        items: {
            type: Array,
            required: true,
        },
        filters: {
            type: Array,
            required: true,
        },
    },

    components: {
        BaseAccordion,
        ToolboxItemCard,
        PaginationNav,
    },

    data() {
        return {
            selectedFilters: [],
            filteredItems: [],
            areFiltersVisible: false,
            pageLimit: 12,
            currentPageId: 1,
            pageCount: null,
            searchQuery: '',
        }
    },

    computed: {
        availableFilters() {
            let filters = JSON.parse(JSON.stringify(this.filters));

            for (let i in filters) {
                filters[i].options = filters[i].options.filter(option => {
                    let isFilterAvailable = false;

                    for (let j in this.filteredItems) {
                        if (this.filteredItems[j].filters.includes(option.slug)) {
                            isFilterAvailable = true;
                            break;
                        }
                    }

                    return isFilterAvailable;
                });
            }

            return filters;
        },

        paginatedItems() {
            const prevRange = (this.currentPageId - 1) * this.pageLimit;
            const currentRange = this.currentPageId * this.pageLimit;

            return this.filteredItems.filter((item, index) => {
                return index >= prevRange && index < currentRange;
            });
        },
    },

    watch: {
        selectedFilters() {
            // Do no filter if we have a search query
            if (this.searchQuery && this.searchQuery !== '') {
                return;
            }

            this.filterItems();
        },

        areFiltersVisible(newVal) {
            // If user opens filters aside, reset search query and filtered items
            if (newVal) {
                this.filterItems();
            }
        },

        currentPageId() {
            window.scrollTo({top: 0});
        },
    },

    mounted() {
        this.filterItems();

        const pageGetParams = new URLSearchParams(window.location.search);

        let filters = [];

        for (let param of pageGetParams.entries()) {
            filters.push(param[1]);
        }

        this.selectedFilters = filters;
    },

    methods: {
        filterItems() {
            // Reset search query
            this.searchQuery = '';

            this.currentPageId = 1;
            this.filteredItems = this.items;

            for (let i in this.selectedFilters) {
                this.filteredItems = this.filteredItems.filter(item => {
                    return item.filters.includes(this.selectedFilters[i]);
                });
            }

            this.updatePageCount();
        },

        searchItems() {
            // Reset checkbox filters
            this.selectedFilters = [];
            this.areFiltersVisible = false;

            this.currentPageId = 1;
            this.filteredItems = [];
            const searchQueryArray = this.normalizeString(this.searchQuery).split(" ");

            for (let i in searchQueryArray) {
                this.filteredItems = this.filteredItems.concat(this.items.filter(item => {
                    return this.normalizeString(item.title).includes(searchQueryArray[i]) || this.normalizeString(item.description).includes(searchQueryArray[i]);
                }));
            }

            this.updatePageCount();
        },

        updatePageCount() {
            this.pageCount = Math.ceil(this.filteredItems.length / this.pageLimit);
        },

        normalizeString(string) {
            return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
    },
}
</script>

<style scoped lang="scss">
    .toolbox-list {
        &__filter-heading {
            &::after {
                content: '';
                background-image: url('@/static/images/icons/chevron-down-black.svg');
                @apply w-6 h-6 bg-cover bg-center transition-transform duration-200;
            }
        }

        :deep(.toolbox-list__filters) {
            .base-accordion__button--opened {
                .toolbox-list__filter-heading {
                    &::after {
                        @apply rotate-180;
                    }
                }
            }
        }
    }
</style>
