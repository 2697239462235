<template>
    <div class="header-nav xl:flex xl:justify-center bg-beige py-4 xl:py-6 text-grey-11">
        <overlay-fade-transition>
            <header-overlay-mobile
                v-if="isOverlayOpened"
                :items="items"
                @close="toggleOverlay"
            >
                <account-btn
                    :is-user-logged-in="isUserLoggedIn"
                    :profile-url="profileUrl"
                    :login-url="loginUrl"
                    :logout-url="logoutUrl"
                ></account-btn>
            </header-overlay-mobile>
        </overlay-fade-transition>

        <transition name="banner-slide">
            <div v-if="isFixed" class="header-nav__banner-container w-full fixed z-50 px-4 mx-auto xl:px-8">
                <div v-if="schoolCount && !isOverlayOpened" class="header-nav__banner-school-count absolute left-4 xl:left-8 -bottom-7 flex items-center bg-blue text-white pb-1 pt-5 px-8 xl:px-16">
                    <p class="body-3">{{ schoolCount }} écoles participantes</p>
                </div>

                <header class="header-nav__banner relative flex items-center justify-between bg-white border border-grey-88 px-4 py-3 xl:px-6">
                    <nav class="flex items-center h-8 xl:h-auto">
                        <slot name="logo-wwf"></slot>
                        <div class="w-px h-5 bg-grey-80 mx-2 xl:mx-6"></div>
                        <slot name="logo-ecole-jardiniere"></slot>

                        <ul class="hidden xl:flex items-center ml-2" aria-label="Navigation principale">
                            <template v-for="(item, key) in items" :key="key">
                                <li v-if="item.type === 'link'">
                                    <a
                                        class="body-2 mx-4 hover:text-yellow transition-colors duration-200"
                                        :href="item.link.url"
                                    >{{ item.link.label }}</a>
                                </li>

                                <li v-if="item.type === 'submenu'" class="header-nav__menu-item relative" @mouseenter="currentMenuItemHovered = key" @mouseleave="currentMenuItemHovered = null">
                                    <span
                                        class="body-2 mx-4 hover:text-yellow transition-colors duration-200"
                                    >{{ item.heading }}</span>

                                    <transition name="submenu-slide">
                                        <ul
                                            class="header-nav__submenu absolute top-full left-0 flex flex-col w-auto bg-white px-4 pb-4 pt-8 rounded"
                                            v-if="item.submenu.length && currentMenuItemHovered === key"
                                        >
                                            <template v-for="(subitem, i) in item.submenu" :key="`submenu-${i}`">
                                                <li class="text-nowrap my-1">
                                                    <a
                                                        :href="subitem.url"
                                                        class="body-3 hover:text-yellow transition-colors duration-200"
                                                    >{{ subitem.label }}</a>
                                                </li>
                                            </template>
                                        </ul>
                                    </transition>
                                </li>
                            </template>
                        </ul>
                    </nav>

                    <account-btn
                        class="hidden xl:flex"
                        :is-user-logged-in="isUserLoggedIn"
                        :profile-url="profileUrl"
                        :login-url="loginUrl"
                        :logout-url="logoutUrl"
                    ></account-btn>

                    <button class="xl:hidden p-1" @click.prevent="toggleOverlay" aria-label="Ouvrir / fermer le menu">
                        <img v-if="!isOverlayOpened" src="@/static/images/icons/burger.svg" alt="Menu" aria-hidden="true">
                        <img v-else src="@/static/images/icons/cross.svg" alt="Menu" aria-hidden="true">
                    </button>
                </header>
            </div>
        </transition>
    </div>
</template>

<script>
import HeaderOverlayMobile from "@/app/components/HeaderOverlayMobile";
import AccountBtn from "@/app/components/AccountBtn";
import OverlayFadeTransition from "@/app/transitions/OverlayFadeTransition";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export default {
    name: "HeaderNav",

    props: {
        items: {
            type: Array,
            required: true,
        },
        schoolCount: {
            type: Number,
            default: null,
        },
        isUserLoggedIn: {
            type: Boolean,
            required: true,
        },
        profileUrl: {
            type: String,
            default: null,
        },
        logoutUrl: {
            type: String,
            default: null,
        },
        loginUrl: {
            type: String,
            default: null,
        },
    },

    components: {
        HeaderOverlayMobile,
        AccountBtn,
        OverlayFadeTransition,
    },

    data() {
        return {
            lastScrollY: 0,
            isFixed: true,
            currentMenuItemHovered: null,
            isOverlayOpened: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    unmounted() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        toggleOverlay() {
            this.isOverlayOpened = !this.isOverlayOpened;

            if (this.isOverlayOpened) {
                disableBodyScroll(document.body);
                return;
            }

            enableBodyScroll(document.body);
        },

        scrollHandler() {
            this.isFixed = window.scrollY <= 80 || window.scrollY < this.lastScrollY;
            this.lastScrollY = window.scrollY;
        },
    },
}
</script>

<style scoped lang="scss">
    .header-nav {
        height: 5.5rem;

        @screen xl {
            height: 7.75rem;
        }

        &__banner-container {
            max-width: 84rem;
        }

        &__banner {
            border-radius: 0.6875rem;
        }

        &__submenu {
            width: 11.5rem;
        }

        &__banner-school-count {
            border-radius: 0.6875rem;

            &::before {
                content: '';
                background-image: url('@/static/images/icons/plant.svg');
                @apply w-4 h-4 bg-center bg-cover mr-2;
            }
        }
    }

    .submenu-slide-enter-active,
    .submenu-slide-leave-active {
        transition: opacity 0.2s ease, transform 0.2s ease;
    }

    .submenu-slide-enter-from,
    .submenu-slide-leave-to {
        @apply -translate-y-4 scale-95 opacity-0;
    }

    .banner-slide-enter-active,
    .banner-slide-leave-active {
        transition: opacity 0.2s ease, transform 0.2s ease;
    }

    .banner-slide-enter-from,
    .banner-slide-leave-to {
        @apply -translate-y-10 opacity-0;
    }
</style>
