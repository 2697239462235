<template>
    <div ref="root" class="hero-home-animation">
        <slot></slot>
    </div>
</template>

<script>
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "HeroHomeAnimation",

    mounted() {
        const image = this.$refs.root.querySelector('.hero-home__image');

        this.$nextTick(() => {
            let timeline = gsap.timeline({paused: true});

            timeline.fromTo(image,{
                y: -50,
            }, {
                y: 50,
            });

            ScrollTrigger.create({
                trigger: '.hero-home-animation',
                start: '+=20',
                scrub: 2,
                animation: timeline,
            });
        })
    }
}
</script>

<style scoped>

</style>
