<template>
    <div class="header-overlay-mobile xl:hidden fixed inset-0 w-screen min-h-screen bg-white z-40 pt-20">
        <div class="grid-container">
            <ul class="p-4">
                <li v-for="(item, key) in items" :key="key" class="my-3">
                    <a v-if="item.type === 'link'" :href="item.link.url" class="body-2">
                        {{ item.link.label }}
                    </a>

                    <base-accordion v-if="item.type === 'submenu'" class="header-overlay-mobile__accordion">
                        <template #button>
                            <span class="header-overlay-mobile__accordion-button flex items-center body-2">
                                {{ item.heading }}
                            </span>
                        </template>

                        <template #content>
                            <ul>
                                <li v-for="(subitem, i) in item.submenu" :key="`submenu-${i}`" class="my-1">
                                    <a
                                        :href="subitem.url"
                                        class="body-3 hover:text-yellow transition-colors duration-200"
                                    >{{ subitem.label }}</a>
                                </li>
                            </ul>
                        </template>
                    </base-accordion>
                </li>

                <li class="mt-8">
                    <slot></slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import BaseAccordion from "@/app/components/BaseAccordion";

export default {
    name: "HeaderOverlayMobile",

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    components: {
        BaseAccordion,
    },
}
</script>

<style scoped lang="scss">
    .header-overlay-mobile {
        &__accordion-button {
            &::after {
                @apply w-4 h-4 bg-cover bg-center ml-2;
                content: '';
                background-image: url('@/static/images/icons/chevron-right-black.svg');
            }
        }

        :deep(.header-overlay-mobile__accordion) {
            .base-accordion__button--opened {
                .header-overlay-mobile__accordion-button {
                    @apply text-yellow font-semibold;

                    &::after {
                        background-image: url('@/static/images/icons/chevron-up-yellow.svg');
                    }
                }
            }
        }
    }
</style>
