<template>
    <button
        :class="backgroundColor ? 'bg-white' : 'bg-beige'"
        class="actor-map-card flex flex-col items-start rounded-2xl py-4 px-6 lg:py-6 lg:px-8 text-left w-full">
        <span class="pill pill--yellow mb-2 inline-flex justify-center items-center">
            <img
                :src="getActorIcon(actor)"
                width="16"
                height="16"
                class="mr-2.5"
            >
            {{ actor.type.label }}
        </span>
        <h5 v-html="actor.name" class="h5 text-grey-11  mb-2"/>
        <p v-html="actor.address" class="body-3 text-grey-40 mb-2"/>
        <p v-html="actor.description" class="body-3 text-grey-40 mb-2"/>
        <a v-if="actor.cta" :href="actor.cta.url" :target="actor.cta.target" v-html="actor.cta.label ? actor.cta.label : actor.cta.url" class="actor-map-card__cta"/>
    </button>
</template>


<script>
const pictoActorAutre = require('@/static/images/icons/autre.svg'),
    pictoActorAssociation = require('@/static/images/icons/association.svg'),
    pictoActorCollectivite = require('@/static/images/icons/collectivite.svg'),
    pictoActorEcole = require('@/static/images/icons/ecole.svg');
export default {
    name: "ActorMapCard",

    props: {
        actor: { type: Object, required: true },
        backgroundColor: { type: Boolean, required: true},
    },
    methods :{
        getActorIcon(actor){
            switch (actor.type.label)
            {
                case 'Association':
                    return pictoActorAssociation;
                case 'Collectivité':
                    return pictoActorCollectivite;
                case 'École':
                    return pictoActorEcole;
                default:
                    return pictoActorAutre;
            }
        },
    }
};

</script>
<style scoped lang="scss">
.actor-map-card {
    @apply relative;

    &__cta {
        @apply relative align-text-bottom text-grey-11 uppercase font-clash-display font-semibold;
        font-size: 0.813rem;

        &::after {
            @apply absolute w-4 h-4 bottom-[6px] -right-5;
            content: url('@/static/images/icons/external-link.svg');
        }
    }
    &__selected {
        &::before {
            content: '';
            @apply absolute w-[4px] h-[70%] left-0 top-[50%] -translate-y-1/2 bg-green rounded-r-xl;
        }
    }
}
</style>
