import "./assets/styles/main.scss";

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput.vue";
import FlashMessage from "@/app/components/FlashMessage.vue";
import HeaderNav from "@/app/components/HeaderNav";
import CarouselGallery from "@/app/components/CarouselGallery";
import OrientatorAnimation from "@/app/components/OrientatorAnimation";
import HeroHomeAnimation from "@/app/components/HeroHomeAnimation";
import CarouselSteps from "@/app/components/CarouselSteps";
import FaqAccordion from "@/app/components/FaqAccordion.vue";
import TestimonialsCarrousel from "@/app/components/TestimonialsCarrousel.vue";
import ActorMap from "@/app/components/ActorMap.vue";
import PasswordDoubleCheck from "@/app/components/PasswordDoubleCheck";
import BaseAccordion from "@/app/components/BaseAccordion.vue";
import ActorMapCard from "@/app/components/ActorMapCard.vue";
import ToolboxList from "@/app/components/ToolboxList";
import ToolboxItemCard from "@/app/components/ToolboxItemCard";
import AddressInputs from "@/app/components/AddressInputs.vue";
import CarouselPinnedContent from "@/app/components/CarouselPinnedContent.vue";
import TextArea from "@/app/components/TextArea.vue";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        FlashMessage,
        HeaderNav,
        CarouselGallery,
        OrientatorAnimation,
        HeroHomeAnimation,
        CarouselSteps,
        FaqAccordion,
        TestimonialsCarrousel,
        PasswordDoubleCheck,
        BaseAccordion,
        ActorMap,
        ActorMapCard,
        ToolboxList,
        ToolboxItemCard,
        AddressInputs,
        CarouselPinnedContent,
        TextArea,
    }
}).mount('#app');
